import {
  captureException,
  FallbackRender,
  ErrorBoundary as SentryErrorBoundary,
} from '@sentry/nextjs';
import dynamic from 'next/dynamic';
import { ReactNode } from 'react';

import { logger } from '@/config/logger';

import { RenderError } from '../RenderError';

const ErrorContent = dynamic(
  async () => (await import('./ErrorContent')).ErrorContent,

  { ssr: false }
);

const OldBrowserError = dynamic(
  async () => (await import('./OldBrowserError')).OldBrowserError,
  { ssr: false }
);

const ErrorBoundaryFallback: FallbackRender = ({ error }) => {
  if (isOldBrowser()) return <OldBrowserError />;

  const errorMessage = error instanceof RenderError ? error.message : null;

  return <ErrorContent errorMessage={errorMessage} />;
};

export const ErrorBoundary = ({ children }: Props) => {
  const handleError = (
    error: unknown,
    componentStack: string | undefined,
    eventId: string
  ) => {
    const reload = () => {
      // The page would refresh all the time if the listener was not removed.
      window.removeEventListener('popstate', reload);
      window.location.reload();
    };

    // Native navigation does not work correctly with ErrorBoundary.
    // Due to that the user is not able to bring back the working sub-page using
    // browser's back button. To make the navigation work, we need to reload
    // the page.
    window.addEventListener('popstate', reload);

    // Don't raport errors related to the old browsers in Sentry
    if (isOldBrowser()) return logger.error('Please update your browser');

    captureException(error);
    logger.error('Uncaught error:', error, componentStack, eventId);
  };

  return (
    <SentryErrorBoundary fallback={ErrorBoundaryFallback} onError={handleError}>
      {children}
    </SentryErrorBoundary>
  );
};

const isOldBrowser = () => {
  if (typeof window === 'undefined') return false;

  const hasObservers = [
    'ResizeObserver',
    'IntersectionObserver',
    'MutationObserver',
  ].every((observer) => observer in window);

  const hasIntlFeatures =
    'Intl' in window &&
    'PluralRules' in window.Intl &&
    'RelativeTimeFormat' in window.Intl;

  const hasEs2019 = typeof Object.fromEntries === 'function';

  return !hasObservers || !hasIntlFeatures || !hasEs2019;
};

interface Props {
  children?: ReactNode;
}
